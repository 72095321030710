/* @import url('https://fonts.googleapis.com/css?family=Poppins:200,400,500,600, 700&display=swap'); */
* {
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}
html {
  font-family: 'Poppins';
  /* width: 100vw; */
}
a {
  color: inherit;
}
p,
h1,
h2,
h3 {
  font-family: Poppins, sans-serif;
}

h3 {
  color: #003594;
  font-size: 28px;
  line-height: 42px;
  padding: 0;
  margin: 0;
  font-weight: bold;
}
p {
  color: #424242;
  text-align: center;
  font-size: 16px;
  margin-bottom: 24px;
}
body {
  font-size: 16px;
  background-color: #f5f5f7;
  margin: 0;
  padding: 0;
}
